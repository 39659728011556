import { Box, Typography, useTheme } from "@mui/material";
import colors from "../lib/colors";

interface AnalysisDataDisplayProps {
    data: string
}

export function AnalysisDataDisplay({ data }: AnalysisDataDisplayProps) {
    const theme = useTheme();
    return (
        <Box width={"100%"} height={"100%"} overflow={"auto"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
            <Box
                borderRadius={theme.spacing(4)}
                bgcolor={colors.greyDarkest}
                color={"white"}
                padding={theme.spacing(4)}
                marginLeft={theme.spacing(4)}
                marginRight={theme.spacing(4)}
            >
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </Box>
        </Box>
    )
}